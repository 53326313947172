<template>
  <div class="mainwidth">
    <!-- 金融服务 -->
    <h2>金融服务</h2>
    <div class="FinancialServe">
      <div class="serve-item" v-for="(item, index) in list" :key="index">
        <h2>{{ item.title }}</h2>
        <div class="content">
          <div style="width: 48%;">
            <img :src="item.img" alt="" />
          </div>
          <div style="display: flex;flex-direction: column;width: 48%;">
            <div style="height: 90%">{{ item.text }}</div>
            <div class="survey" @click="handleClickSurvey(index)">问卷调查</div>
            <div class="look-more" @click="handleClickService(index)">
              查看更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 政策申报 -->
    <h2>政策申报</h2>
    <div class="policy">
      <div style=" display: flex;justify-content: space-between;">
        <div class="policy-img">
          <img src="../assets/images/zcsb.jpg" alt="" />
        </div>
        <div class="policy-info">
          <h2 @click="handleClickDetails(objInfo)">{{ objInfo.title }}</h2>
          <!-- <div v-html="objInfo.content"></div> -->
          <div class="text-info">
            {{ objInfo.synopsis }}
          </div>
          <div class="more" @click="handleClickMore">查看更多</div>
        </div>
      </div>
      <a-divider></a-divider>
      <!-- 政策列表 -->
      <div class="policy-list">
        <div
          v-for="(item, index) in policyList"
          :key="index"
          class="policy-list-item"
        >
          <div
            style="display: flex;justify-content: space-between;cursor: pointer;font-size: 20px;"
            @click="handleClickDetails(item)"
          >
            <div clas>
              {{ item.title }}
            </div>
            <div>
              {{ item.start_time }}
            </div>
          </div>
          <a-divider></a-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPolicyList } from "../api/financialPrefecture";
import { Base64 } from "js-base64";
import { mapGetters } from "vuex";
export default {
  name: "FinancialPrefecture",
  data() {
    return {
      list: [
        {
          title: "数转智改专项贷款",
          img: require("../assets/images/szzg.png"),
          text:
            "创新“数据资产增信+信用担保+银行+专项政策”，助力工业企业以“数”换“贷” ，单户融资额度原则上最高达 1000万元 ，单笔贷款期限最长达三年。",
        },
        {
          title: "技术改造专项贷款",
          img: require("../assets/images/jsgz.png"),
          text:
            "“技改专项贷”依托龙哈智能产融云平台，构建以“数据资产增信+全面风险管理为基础的“企业数据资产+（信用担保+银行）+专项政策”的专项非标准的金融产品，单户融资额度原则上最高达 2000万元 ，单笔贷款期限最长达三年。",
        },
      ],
      policyList: [
        // {
        //   title: "政策标题政策标题政策标题政策标题",
        //   time: "2024年10月1日",
        // },
        // {
        //   title: "政策标题政策标题政策标题政策标题",
        //   time: "2024年10月1日",
        // },
      ],
      objInfo: {},
    };
  },
  // computed: {
  //   sanitizedHtml() {
  //     // 使用正则表达式移除 <img> 标签
  //     const regex = /<img[^>]*>/gi;
  //     return this.objInfo.content.replace(regex, '');
  //   }
  // },
  computed: {
    ...mapGetters([
      "orgId",
      "isAuth",
      "loginType",
      "orgName",
      "isLogin",
      "userId",
    ]),
  },
  methods: {
    //金融服务
    handleClickService(type) {
      if (type == 0) {
        this.$router.push({ path: "/NumberWisdom" });
      } else if (type == 1) {
        this.$router.push({ path: "/Remould" });
      }
    },
    // 问卷调查
    handleClickSurvey(type) {
      this.$router.push({
        path: "/Survey",
        query: {
          type: type + 1,
        },
      });
    },
    // 查看更多
    handleClickMore() {
      this.$router.push({
        path: "/PolicyList",
      });
    },
    // 详情
    handleClickDetails(item) {
      this.$router.push({
        path: "/PolicyDetails",
        query: {
          id: item.id,
          status: item.review_status,
        },
      });
    },
    getList() {
      getPolicyList({
        type: 3,
        user_id: this.userId ? this.userId : "null",
        currentPage: 1,
        showCount: 3,
      }).then((res) => {
        console.log(res);
        this.$nextTick(() => {
          this.policyList = res.varList;
          this.objInfo = res.varList[0];
          // this.objInfo.title = this.policyList[0].title;
          // this.objInfo.synopsis = this.policyList[0].synopsis;
          // this.objInfo.id = this.policyList[0].id;
          // this.objInfo.status = this.policyList[0].review_status;
          // const regex = /<img[^>]*>/gi;
          // this.objInfo.content =  Base64.decode(this.policyList[0].content).replace(regex, '');
          // console.log(this.objInfo);
        });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  .FinancialServe {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .serve-item {
      box-shadow: 0px 2px 10px #929292;
      border: 1px solid #ccc;
      width: 49%;
      padding: 20px 20px;
      // cursor: pointer;
      h2 {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        width: 100%;
        height: 285px;
      }
      .look-more {
        background: #4095e5;
        border-radius: 10px;
        color: #fff;
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0px;
      }
      .survey {
        background: #4095e5;
        border-radius: 10px;
        color: #fff;
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 120px;
      }
    }
  }
  .policy {
    box-shadow: 0px 2px 10px #929292;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    .policy-img {
      width: 49%;
      img {
        width: 100%;
        height: 200px;
      }
    }
    .policy-info {
      width: 49%;
      position: relative;
      cursor: pointer;
      h2 {
        font-weight: bold;
        font-size: 20px;
      }
      .more {
        background: #4095e5;
        border-radius: 10px;
        color: #fff;
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0px;
      }
    }
  }
  .policy-list {
    margin-top: 20px;
    .policy-list-item {
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.text-info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
